import '@shoelace-style/shoelace/dist/components/dropdown/dropdown';
import '@shoelace-style/shoelace/dist/components/menu/menu';
import '@shoelace-style/shoelace/dist/components/menu-item/menu-item';
import '@shoelace-style/shoelace/dist/components/menu-label/menu-label';
import '@shoelace-style/shoelace/dist/components/alert/alert';
import '@shoelace-style/shoelace/dist/components/icon/icon';
import '@shoelace-style/shoelace/dist/components/button/button';
import '@shoelace-style/shoelace/dist/components/tooltip/tooltip';
import '@shoelace-style/shoelace/dist/components/divider/divider';
import '@shoelace-style/shoelace/dist/components/icon-button/icon-button';
import '@shoelace-style/shoelace/dist/components/tab-group/tab-group';
import '@shoelace-style/shoelace/dist/components/tab-panel/tab-panel';
import '@shoelace-style/shoelace/dist/components/tab/tab';
import '@shoelace-style/shoelace/dist/components/popup/popup';
import '@shoelace-style/shoelace/dist/components/dialog/dialog';
import 'utilities/setup-shoelace';
import 'components/nonprofit-search-bar';
import 'components/notification-bell';
import 'components/help-dialog';
import { tableStyles } from 'styles/table';
import { fileInputStyles } from 'styles/file-input';
import { CSSResultGroup } from 'lit';
import  "utilities/toaster";
import * as Sentry from "@sentry/browser";

function addStylesToDocument(tableStyles: CSSResultGroup) {
	const styleNode = document.createElement('style');
	styleNode.innerHTML = tableStyles.toString();
	document.head.appendChild(styleNode);
}
// Styles shared between the document and components.
// Write these styles as CSS-in-TS so they can be imported into components,
// and add them to the document here.
addStylesToDocument(tableStyles);
addStylesToDocument(fileInputStyles);

window.addEventListener('load', (event) => {
	document.querySelectorAll('sl-alert.toast').forEach(alert => {
		(alert as any).toast();
	});
});

// Sentry configuration
if (["production", "staging"].includes(process.env.NODE_ENV)) {
	// Set user information
	Sentry.setUser({
		email: (window as any).change_user_email, // Replace with the actual user's email
	});

	Sentry.init({
		dsn: "https://a043002bfa73f2a08749dd62c9d91ffd@o530221.ingest.sentry.io/4506821264343040",
		environment: process.env.NODE_ENV,
		integrations: []
	});
}
